.p-checkbox {

  &-disabled {
    pointer-events: none;
  }

  &--small {
    .p-checkbox-box {
      width: 16px;
      height: 16px
    }
  }

  .p-checkbox-box {
    &.p-highlight {
      background: kt-get($theme-colors, primary);
      border-color: kt-get($theme-colors, primary);
    }

    &.p-disabled {
      background: $border-primary-color;
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-focus {
        box-shadow: none;
        border-color: kt-get($theme-colors, primary);
      }

      &.p-highlight {
        &:hover {
          background: kt-get($theme-colors, primary);
          border-color: kt-get($theme-colors, primary);
        }
      }
    }
  }
}
