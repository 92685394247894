.p-chip {
  padding: 0.225rem 1rem;

  &-wrapper {
    padding: 0;
  }

  &-content {
    padding: 0.225rem 1rem;
  }

  &-text {
    margin: 0;
  }

  &-remove-icon {
    line-height: initial;
  }

  &-child {
    @extend .btn;

      padding: 0;
      margin: 0;
      border: 0;

      > [class*=" la-"] {
          font-size: 1rem;
          padding-right: 0;
      }
  }

  @each $name, $color in $theme-colors {
    // Solid style
    &-#{$name} {
      color: if(lightness($color) < 50%, $light, $dark);
      border: 1px solid $color;
      background-color: $color;

      i {
        color: if(lightness($color) < 50%, $light, $dark);
      }
    }

    // Outline style
    &-outline-#{$name} {
      background-color: transparent;
      color: $color;
      border: 1px solid $color;
    }
  }
}
