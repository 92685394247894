.amplify {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px #00000024;
  border-radius: 8px;
  padding: 36px 41px 31px;
  width: 377px;
  margin: auto;
  font-family: $font-family-base;

  @include kt-tablet-and-mobile {
    position: relative;
    left: 10px;
    right: 10px;
    top: 10px;
    padding: 20px;
    transform: inherit;
  }

  @include kt-mobile {
    position: absolute;
    width: auto;
    left: 10px;
    right: 10px;
    top: 10px;
    padding: 20px;
    box-sizing: border-box;
    transform: inherit;
  }

  .p-divider {
    width: 100%;

    &.p-divider-horizontal {
      &:before {
        border-color: #E6E6E6;
      }
    }
  }

  &__logo {
    width: 241px;
    margin-bottom: 10px;

    @include kt-tablet-and-mobile {
      width: 175px;
    }
  }

  form {
    width: 100%;
    margin-top: 0.5rem;

    @include kt-tablet-and-mobile {
      margin-top: 1.5rem;
    }
  }

  button {
    width: 100%;
    position: relative;
  }

  amplify-loading-spinner{
    --spinner-bar-fill: #15B7FF;
  }

  amplify-authenticator {
    --container-height: auto;
    --box-shadow: 0;
    --padding: 2rem 2rem 0;
    --amplify-primary-color: #15B7FF;
    --amplify-primary-tint: #15B7FF;
    --amplify-primary-shade: #15B7FF;
    --container-display: block;
    display: none;
  }

  amplify-sign-in{
    width: 100%;
  }
}
