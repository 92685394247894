.p-sidebar {
  $self: &;

  @include kt-media-below(lg) {
    padding-bottom: 50px;
  }

  &.p-sidebar-right {
    max-height: 100%;
    overflow: auto;

    @include kt-tablet-and-mobile {
      &#{$self}-md {
        width: 100%
      }
    }
  }

  &.p-sidebar-bottom {
    @include kt-media-below(lg) {
      height: unset;
    }
  }

  .sidebar-header {
    border-bottom: 1px solid $gray-200;
    font-size: 16px;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 99;
  }

  .sidebar-body {
    font-size: 15px;

    .sidebar-consent {
      .text-light-purple {
        color: kt-get($kt-base-colors, shape, 4)
      }

      .label-inline {
        font-size: 13px;
        border-width: 2px;
        display: unset;
        line-height: 20px;

        &.label-outline-success i {
          color: $success;
        }

        &.label-outline-danger i {
          color: $danger;
        }
      }

      .durability {
        font-size: 12px;
      }

      .behaviour {
        position: relative;
        border-radius: 8px 0 0 8px;
        border-left: none;
        box-shadow: 0 0 10px #0000001A;

        &::before {
          content: '';
          width: 10px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 8px 0 0 8px;
        }

        &__afr::before {
          background-color: $primary;
        }

        &__type_veh::before {
          background-color: $danger;
        }

        &__customer_joinability::before {
          background-color: $success;
        }

        &__key_infos::before {
          background-color: $yellow;
        }

        .card-header {
          font-size: 16px;
          height: unset;
        }

        .card-body {
          font-size: 13px;
        }
      }
    }
  }

}

.sidebar__menu-mobile {
  background-color: kt-get($kt-state-colors, neutralViolet, base);
  color: kt-get($kt-state-colors, neutral, base);
  border: none;
  &--menu-item .btn {
    color: kt-get($kt-state-colors, neutral, base) !important;
  }
  &--menu-item a {
    color: kt-get($kt-state-colors, neutral, base) !important;
  }
}