.tfoot{
  $self: &;

  &--total{
    td{
      border: 0;
      padding: 15px 0;
      color: kt-get($kt-font-color, text);
    }

    .tfoot__bordered{
      padding: 5px 0px;
      background: kt-get($kt-state-colors, light-gray, base);
    }

    #{$self}__total{
      padding-left: 15px;
      margin-left: -5px;
      border-radius: 18px 0 0 18px;
    }

    #{$self}__number{
      padding-left: 10px;
      border-radius: 0 18px 18px 0;
    }

    .tfoot__float-right{
      width: 55%;
      float: right;
    }
  }
}
