.months{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -0.3rem -1rem;

  .appointment-month{
    border-radius: 17px;
    width: 17%;
    border: 1px solid $light-gray;
    font-size: 15px;
    line-height: 24px;
    padding: 0.2rem 0;
    margin: 0 0.3em 1rem;
    font-weight: 500;
    text-align: center;
    cursor: pointer;

    &--selected{
      background: kt-get($kt-state-colors, brand, base);
      color: white;
      border: 1px solid kt-get($kt-state-colors, brand, base);
    }
  }
}
