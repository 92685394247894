.p-inputmask {
  @each $color, $value in $theme-colors {
    &-#{$color} {
      color: $value !important;

      &:focus {
        color: $value !important;
      }
    }
  }
}
