.headband {
  $self: &;
  width: 100%;
  margin: -15px -3px 25px;
  transition: all 0.25s ease;

  @include kt-tablet-and-mobile {
    width: 100%;
    margin: 0;
  }

  @each $name, $color in $theme-colors {
    &--#{$name}{
      background: transparent linear-gradient(266deg, #FFFFFF 0%, $color 100%) 0% 0% no-repeat padding-box;
    }

    &--reverse-#{$name} {
      background: transparent linear-gradient(266deg, $color 0%, $color 10%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    }

    &--top-#{$name} {
      background: transparent linear-gradient(to bottom, $color 0%, $color 10%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    }
  }

  @each $name, $color in $customers-headbands {
    &--customers-#{$name} {
      background: $color;
    }
  }

  &__col{
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &__title{
    font-size: 21px;
    line-height: 31px;
    margin-bottom: 15px;

    i {
      color: kt-get($kt-font-color, text);
    }
  }

  &__subtitle{
    font-size: 16px;
    line-height: 25px;
    margin-top: 15px;
  }

  &--header {
    margin: 0;
    width: 100%;
  }

  img {
    height: 100%;
    transition: all 0.25s ease;;
  }

  &__content{
    height: 100%;
    margin-left: 5%;
    align-items: center;
    display: flex;
    transition: all 0.25s ease;

    @include kt-media-below(md) {
      margin: 0;
    }
    &--campaign{
      margin-left: 20%;
    }
  }

  &__content--scroll{
    display: none;
    margin-left: 0;
    justify-content: center;
    height: 100%;
    transition: all 0.25s ease;
  }

  &--scroll {
    #{$self}__content {
      display: none;
    }

    #{$self}__content--scroll {
      display: flex;
    }
  }
}
