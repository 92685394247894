.swal2-container{

  .swal2-modal{
    min-width: 45rem;

    @include kt-tablet-and-mobile {
      min-width: 0;
    }
  }

  .swal2-select{
    display: flex;
    outline: none;
    margin: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
  }

  .swal2-title{
    color: kt-get($kt-state-colors, brand, base);
  }

  .swal2-popup{
    padding: 0;

    .btn {
      margin: 0;
    }

    .swal2-actions {
      border-top: 1px solid $light-gray;
      padding: 1.5rem 0;
      margin: 1.5rem 0 0;
    }

    .swal2-title{
      margin: 1rem 0 0 0;
    }

    .swal2-icon{
      border-color: transparent;

      .swal2-icon-content{
        font-size: 2rem;
      }

      &.swal2-warning{
        border-color: kt-get($kt-state-colors, warning, base);
        color: kt-get($kt-state-colors, warning, base);
        width: 2.5rem;
        height: 2.5rem;
        margin: 2rem auto 0;
      }

      &.swal2-question{
        border-color: kt-get($kt-state-colors, primary, base);
        color: kt-get($kt-state-colors, primary, base);
        width: 2.5rem;
        height: 2.5rem;
        margin: 2rem auto 0;
      }
    }
  }
}
