.picture-upload {
  $self: &;
  text-align: center;
  height: 210px;

  &.is-invalid {
    box-shadow: none;

    #{$self}__wrapper {
      border: 1px solid kt-get($kt-state-colors, danger, base);
    }
  }

  &__wrapper {
    transition: all 0.2s ease;
    border: 2px solid #EBEDF2;
    padding: 25px 15px;
    margin: 0 0 20px 0;
    cursor: pointer;
    height: 100%;

    &:hover {
      box-shadow: 0 0 5px -1px $light-gray;

      #{$self}__title {
        transform: scale(1.05);
      }
    }
  }

  &__title {
    margin: 0;
    cursor: pointer;
  }

  &__input {
    display: none
  }

  &__picture {
    margin-top: 10px;
  }

  &__logo {
    font-size: 8em;
  }

  &__previews {
    margin-bottom: 20px;
    height: 100%;

    &--empty{
      margin: 0;
      height: 0;
    }

    #{$self}__preview {
      position: relative;
      height: 100%;

      &--loading{
        display: none;
      }

      #{$self}__image{
        max-height: 100%;
        height: 100%;
        object-fit: contain;
        width: 100%;
      }

      #{$self}__remove {
        color: black;
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 1.2em;
        cursor: pointer;

        i{
          z-index:1;
          position: relative;
        }

        &-background {
          content: '';
          opacity: 0.5;
          position: absolute;
          left: 50%;
          top: calc(50% - 1px);
          width: 30px;
          height: 30px;
          transform: translate(-50%, -50%);
          background: white;
          border-radius: 50%;
        }
      }
    }
  }
}
