.p-datatable .p-datatable-tbody > tr.expanded-row {
  background: $primary-light-light;

  & + tr > td {
    padding: 0;
  }

  @each $name, $color in $leads-type {
    &__origin-bg--#{$name} .leadType {
      width: 10px;
      background-color: $color;
    }
  }
}

.leadType {
  width: 0;
  height: 100%;
  position: absolute;
  left: -10px;
  top: 0;
  border-radius: 8px 0 0 8px;
}

.status-date {
  font-size: 11px;
}

.text {
    @each $name, $color in $grays {
        &-gray-#{$name} {
            color: $color
        }
    }
}

.event-detail-container {
  background-color: #EAEAEA;
  padding-left: 0.857rem;
  padding-right: 0.857rem;
}

.event {
  @each $name, $color in $leads-type {
    &__origin-bg--#{$name}{
      background-color: $color;
    }
  }

  @each $name, $color in $leads-type {
    &__origin-bg-opacity--#{$name}{
      background-color: rgba($color, 0.2);
    }
  }

  @each $name, $color in $leads-type {
    &__origin--#{$name}{
      color: $color !important;
      height: auto;
    }
  }

  .p-datatable .p-datatable-thead > tr > th {
    background-color: $white;
    border-width: 0 1px 1px;

    &:first-child {
      border-left-width: 0;
    }
    &:last-child {
      border-right-width: 0;
    }
  }

  .p-datatable .p-datatable-tbody > tr {
    &:nth-child(odd) {
      background-color: #F8F9FB;
    }

    & > td {
      border-color: #E6E6E6;
      border-width: 0 1px;

      &:first-child {
        border-left-width: 0;
      }
      &:last-child {
        border-right-width: 0;
      }
    }
  }

  .project {
    font-size: 14px;
    color: $text-muted;
    font-weight: bold;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    border-radius: 0;
    text-decoration: none;
  }
}

  .comment {
    border: 1px solid $gray-200;
    padding: 10px;
    flex: 1;
    border-radius: 3px;
  }

.pin {
  width: 36px;
  height: 32px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  left: -10px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-top: 10px solid #000;
    clear: both;
    position: absolute;
    bottom: -10px;
    left: 0;
  }
}

.total-price {
  background-color: $primary;
  color: $white;
  width: 23%;
  padding: 0.571rem 0.857rem;
  border-radius: 50px;
  font-size: 15px;
  font-weight: bold;
}

.vehicle-container {
  border: 1px solid $border-range-color;
  border-radius: 3px;

  &--full {
    border: none;
    border-radius: 0;
  }

  & > img {
    height: 215px;
    object-fit: cover;
  }

  .marque-modele {
    background-color: $white;
    box-shadow: 0 3px 6px #E6EAF5;
    border-radius: 37px;
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__part-exchange {
      .marque {
        font-size: 20px;
        font-weight: bold;
        color: kt-get($kt-font-color, text);
      }

      .veh-infos {
        font-size: 12px;
      }
    }

    .marque {
      font-size: 18px;
      line-height: 27px;
      font-weight: bold;
      color: kt-get($kt-font-color, text);
    }

    .version {
      font-size: 14px;
    }

    .fluidity-label {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .fluidity-banner {
    background-color: theme-light-color('closed');

    .fluidity-banner_image {
      position: relative;
      width:15%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include kt-media-range(xlg, xxl) {
        display: none;
      }

      img {
        height: 115%;
        position: absolute;
      }
    }

    .fluidity-banner_label {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .label {
        box-shadow: 0px 3px 6px #7A2BBF26;
        background-color: theme-light-color('light');
      }
    }
  }

  .button-container, {
    border-top: 1px solid $border-range-color;
  }

  .price {
    width: 100%;
    text-align: center;
    border-top: 1px solid $border-range-color;

    .label {
      font-size: 14px;
    }

    .price-published {
      font-size: 18px;
    }

    .price-content {
      font-weight: bold;
      font-size: 30px;
      color: kt-base-color(label, 3);
    }
  }

  .estimate {
    font-size: 20px;
    border-top: 1px solid $border-range-color;
    color: kt-base-color(label, 3);

    &__part-exchange {
      font-size: 18px;
      box-shadow: 0 3px 6px #E6EAF5;
      border-radius: 37px;
    }

    &__modified {
      font-size: 15px;
    }
  }

  .vehicle-recovery {
    font-size: 15px;
  }

  .vehicle-detail {
    border-top: 1px solid $border-range-color;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .vehicle-detail_content {
      font-size: 18px;
    }
  }
}
