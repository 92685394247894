$slider-handle: (
  size: 25px,
  height: 10px
);

.p-slider{
  border-radius: 0;
  background: kt-get($kt-state-colors, light-gray, base);

  &.p-slider-horizontal {
    height: kt-get($slider-handle, height);

    .p-slider-handle-start {
      margin-top: - calc(kt-get($slider-handle, size) / 2);
      margin-left: -1px;
    }

    .p-slider-handle-end {
      margin-top: - calc(kt-get($slider-handle, size) / 2);
      margin-left: - kt-get($slider-handle, size) + 1px;
    }
  }

  .p-slider-range {
    background: kt-get($kt-state-colors, primary, base);
  }

  .p-slider-handle{
    height: kt-get($slider-handle, size);
    width: kt-get($slider-handle, size);
    border: 1px solid $light-gray;
  }

  &:not(.p-disabled) {
    .p-slider-handle {
      &:hover{
        border-color: kt-get($kt-state-colors, primary, base);
      }
    }
  }
}
