.label--valid{
  &--short{
    border-radius: 0.42rem!important;

    & > i{
      color: #0abb87;
      font-size: 1.5rem;
    }
  }

  &--long{
    padding: 0.65rem 1rem!important;
    font-size: 1rem!important;

    & > i{
      color: #0abb87;
      font-size: 1.5rem;
      margin-right: 0.25rem;
    }
  }
}