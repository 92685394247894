.btn-primary:not(:disabled):not(.disabled).active {
  background: kt-state-color(light-gray, base);
  border: 2px solid kt-state-color(light-gray, base);
  color: kt-state-color(primary, base);
}

.event-popover_title {
  text-align: center;
  font-weight: bold;
  img, i {
    margin-right: 12px;
  }
}

.fc {
  .fc-toolbar {
    button {
      background: white;
      border: 2px solid kt-state-color(light-gray, base);
      border-radius: 3px;
      color: #868AA8;

      &:hover,
      & .btn-primary:not(:disabled):not(.disabled).active {
        background: kt-state-color(light-gray, base);
        color: kt-state-color(primary, base);
      }
    }
  }

  .fc-header-menu {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;

    .fc-header-menu_content {
      img, i {
        margin-right: 7px;
      }

      span {
        color: #FFFFFF;
        font-size: 14px;
        line-height: 21px;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .fc-scrollgrid {
    border: 2px solid kt-state-color(light-gray, base);
  }

  .fc-col-header-cell {
    color: white;
    text-transform: uppercase;
    
    &.fc-day-today {
      div {
        background-color: #eb5b54;
      }
    }
    
    div {
      padding: 6px 2px;
      background: kt-state-color(primary, base);
      border-radius: 2px;
    }
  }

  .fc-timegrid-axis-cushion {
    width: 50px;
    font-size: 10px;
  }

  .fc-col-header-cell-cushion {
    font-size: 12px;
  }

  .fc-event {
    background: kt-state-color(light-gray, base);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
    color: #FFFFFF;
    border: none;
    padding: 4px 8px 4px 8px;

    .fc-event-main {
      display: flex;

      img, i {
        margin-right: 12px;
      }

      .fc-event-time {
        font-weight: bold;
        font-size: 12px;
        white-space: normal;
        width: 100%;
      }

      .fc-event-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
      }

    }

  }

  .fc-non-business {
    background-color: hsla(230, 100%, 96%, .6);
  }

  .fc-list-day {
    box-shadow: 0px 3px 6px #00000029;
    .table-active {
      background-color: #f2f3f8 !important;
    }
    .fc-list-day-text {
      text-transform: capitalize;
    }
  }

  .fc-daygrid-more-link {
    font-weight: bold;
  }

  .fc-daygrid-event {
    display: flex;
    color: #FFFFFF;

    img, i {
      margin-right: 12px;
    }

    .fc-event-time {
      font-weight: bold;
      font-size: 12px;
    }

    .fc-event-title {
      font-weight: 400;
      font-size: 12px;
      white-space: normal;
    }
  }

  .fc-list-event {
    background: #FFFFFF;
    color: #525672;

    .fc-list-event-graphic {
      padding: 8px 4px;
    }
  }

  .fc-highlight {
    background: lighten(kt-get($kt-state-colors, primary, base), 15%);
    opacity: 0.4;
  }

  .fc-v-event {

    .fc-event-main-frame {
      width: 100%;
    }
  }

  .fc-event-main-frame {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .fc-timegrid-now-indicator-line {
    border-width: 3px;
    border-color: #FF4D4D;
  }

  .fc-timegrid-slot {
    position: relative;
    height: 12px;
    font-size: 0.6rem;

    &-label {
      display: block;
      width: 50px;
      position: relative;
    }
  }

  .fc-timegrid-slot-label-cushion {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 12px;
    z-index: 1;
    border-top: 1px solid #E8E8E9;
    border-bottom: 1px solid #E8E8E9;
  }

  .fc-scrollgrid-section:not(.fc-scrollgrid-section-liquid) {
    .fc-scroller {
      /*OVERRIDE FULL CALENDAR INLINE STYLE*/
      overflow: hidden !important;
    }
  }

  .fc-toolbar-title {
    text-align: center;
    font-size: 1.2rem;
    padding: 0 10px;

    @include kt-mobile {
      font-size: 1rem;
      padding: 0 8px;
    }
  }
}
