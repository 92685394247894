.settings-card-wrapper {
  padding: 15px 10px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 240px));
  grid-gap: 30px;

  .settings-card {
    background-color: $white;
    border-radius: 10px;
    width: 100%;
    height: 240px;
    max-width: 240px;

    &:hover {

      .la {
        opacity: 0.4;
        text-shadow: 0 3px 20px;
      }
    }

    .la {
      opacity: 0.3;
      transition: all 0.2s ease-in-out;
    }
  }
}
