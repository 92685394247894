.p-dropdown {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-weight: $font-weight-bold;
  height: calc(1.5em + 1.3rem + 2px);
  border: 1px solid #E4E6EF;

  &.is-invalid {
    border-color: kt-get($kt-state-colors, danger, base);
  }

  &:not(.p-disabled) {
    &.is-invalid {
            border-color: kt-get($kt-state-colors, danger, base);
        }
    &.p-focus {
      box-shadow: none;
    }
  }

  &-panel {
    /*OVERRIDE BOOTSTRAP MODALS*/
    z-index: 100000 !important;

    .p-dropdown-items {
      .p-dropdown-item.p-highlight {
          background: kt-get($kt-state-colors, primary, base);
      }
    }
  }

  .p-dropdown-label {
    &.p-inputtext{
      padding: 0;
    }
  }

  .p-dropdown-trigger {
    padding: 0 0 0 16px;
    width: auto;
  }

  .p-dropdown-clear-icon {
    ~ .p-dropdown-trigger {
      padding: 0 0 0 25px;
    }
  }

  .p-dropdown-label-empty {
    overflow: visible;
    visibility: visible;
  }

  // Theme colors
  @each $name, $color in $theme-colors {
    // Solid style
    &--#{$name} {
      background-color: $color;
      border: none;

      .p-dropdown-label {
        &.p-inputtext {
          color: theme-inverse-color($name);
        }
      }

      .p-dropdown-trigger {
        color: theme-inverse-color($name);
      }
    }

    // Light style
    &--light-#{$name} {
      background-color: theme-light-color($name);
      border: none;

      .p-dropdown-label {
        &.p-inputtext {
          color: $color;
        }
      }

      .p-dropdown-trigger {
        color: $color;
      }
    }
  }
  &.select__custom {
    padding: 0.6rem !important;
    width: 250px !important;
    margin: 0 !important;
  }
}
