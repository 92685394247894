.smart-repair-card {
    &__gap {
        gap: 1.5rem;
    }

    &__repair-color {
        color: #FF6726;
    }

    &__repair-color {
        color: #FF6726;
    }

    &__grey {
        color: #7D7D7D;
    }

    &__info-text {
        background-color: #E6E6E6;
        border: none;
        font-weight: 500;
        border-radius: 5px;
        padding: 0.2rem;
        width: 100%;
        color: #000000;
    }

    &__input{
        cursor: pointer !important;
        caret-color: transparent;
    }

    &__img {
        border-radius: 2rem;
    }

    &__zone {
        background-color: #F2F3F8;
        border-radius: 5rem;
        font-weight: bold;
        padding: 0.5rem 2.5rem;
        text-transform: uppercase;
    }

    &__car {
        svg {
            width: 55%;
        }
    }

    &__car--w-30 svg {
        width: 30%;
    }

    &--separate {
        border-left: 1px solid #DFE2EA;
    }

    &--border-bottom {
        border-bottom: 1px solid #DFE2EA !important;
    }

    &__info {
        border-radius: 3rem;
        font-weight: 900;
        padding: 0.5rem 1rem;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        top: -40px;

        &--valid {
            background: #CFF2ED;
            color: #12BEA4;
        }

        &--unvalid {
            background: #FEDBDB;
            color: #FC4D4D;
        }
    }

    &--underline {
        text-decoration: underline;
    }

    &--cursor {
        cursor: pointer;
    }

    &--info-size {
        font-size: 17px;
    }

    &__check {
        color: white;
        font-size: 18px;
        position: absolute;
        top: 33%;

        &:before {
            border-radius: 50%;
            content: "";
            padding: 1rem;
            width: 50px;
        }

        &--active {
            &:before {
                background-color: #12BEA4;
            }
        }

        &--disabled {
            &:before {
                background-color: #FF4D4D;
            }
        }
    }
}