//
// Timeline 6
//

.timeline {
  width: 90%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 151px;
    width: kt-get($timeline-6-config, bar-border-width);
    top: 2px;
    bottom: 0;
    background-color: $gray-200;
  }

  .timeline-item {
    display: flex;
    position: relative;
    margin-bottom: 1.7rem;

    &:last-child {
      margin-bottom: 0;
    }

    .timeline-label {
      width: kt-get($timeline-6-config, label-width);
      margin-right: 15px;
      flex-shrink: 0;
      font-size: 1rem;
      font-weight: 600;
      position: relative;
      color: $dark-75;
      text-align: right;
    }

    .timeline-badge {
      flex-shrink: 0;
      background: white;
      width: kt-get($timeline-6-config, badge-size);
      height: kt-get($timeline-6-config, badge-size);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: relative;
      margin-top: 1px;
      margin-left: -0.5rem;
      padding: 3px !important;
      border: 6px solid kt-get($timeline-6-config, border-color) !important;

      span {
        display: block;
        border-radius: 100%;
        width: 6px;
        height: 6px;
        background-color: $gray-200;
      }

      &__activity {
        z-index: unset;
      }
    }

    &-dealership {
      justify-content: flex-end;

      .timeline-label {
        order: 2;
        text-align: left;
        margin-right: -26px;
        padding-left: 10px;
      }

      .timeline-badge {
        order: 1;
      }
    }

    .timeline-connector {
      width: 100%;
      position: absolute;
      top: 47%;
      left: 0;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--color)
      }
    }

    .timeline-content {
      flex-grow: 1;
    }
  }

  &-handler {
    &.h-auto {
      max-height: inherit;
    }

    &-scrollable {
      overflow-y: auto;
    }
  }

  &-reverse {
    &:before {
      left: 50%;
      transform: translate(15px, 0);
    }

    .timeline-item {
      margin-bottom: 1.7rem;
    }
  }

  &-single {
    &::before {
      left: 0;
      transform: translate(15px, 0);
    }
  }

  &-hidden-bar {
    &:before {
      content: none;
    }
  }

  &-horizontal {
    display: flex;
    justify-content: space-around;
    .timeline-item {
      flex-direction: column;
      width: 100%;
      align-items: start;
      justify-content: end;

      &:last-child {
        margin-bottom: 1.7rem;
      }
      .timeline-label {
        width: auto;
        margin: 0;
      }
      .timeline-label,
      .timeline-content {
        transform: translateX(calc(-50% + 4px));
      }

      .timeline-content {
        flex-grow: unset;
      }
    }

    &-whole {
      .timeline-item {
        .timeline-connector {
          top:35%
        }
        &:last-child,
        &:first-child {
          .timeline-content {
            transform: none;
          }
        }
        &:last-child {
          align-items: end;
          width: 0;
          .timeline-label {
            width: max-content;
            transform: none;
          }
          .timeline-connector:before {
            content: none;
          }

          .timeline-content {
            width: max-content;
          }
        }
      }
    }

    &-partial {
      .timeline-item:first-child,
      .timeline-item:last-child {
        .timeline-connector {
          width: 100%;
          position: absolute;
          top: 47%;
          left: 0;
          &:before {
            content: '';
            display: block;
            width: 100%;
            background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 20px;
            background-repeat: repeat-x;
            background-color: $white;
          }
        }
      }
    }
  }
}

.crvo-handler {
  .timeline {
    .timeline-item {
      .timeline-connector {
        top: unset;
        bottom: 30px;
      }

      .timeline-label {
        max-width: 65%;
        margin-bottom: 20px;
      }

      .timeline-badge {
        position: absolute;
        bottom: 30px;
        transform: translateY(50%);
      }
    }
  }
}
