.p-multiselect {
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.30rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;

  &.is-invalid {
    border: 1px solid kt-get($kt-state-colors, danger, base);
  }

  &-items-wrapper {
    padding: 5px 0;
  }

  &-footer {
    border-top: 1px solid #d4d4d4;
  }

  &-token{
    max-width: 100%;

    &-label{
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .p-multiselect-trigger {
    width: 17px;
    color: black;
  }
}

.p-multiselect-panel {
  z-index: 100000 !important;
}

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-fluid .p-inputgroup .p-input {
  width: 100%;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background: kt-get($kt-state-colors, primary, base);
}

.p-multiselect-panel--empty {
  padding: 8px 12px;

  .p-multiselect-items-wrapper {
    display: none;
  }

  .p-multiselect-footer {
    border-top: none;
  }
}
