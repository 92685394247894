@each $breakpoint, $breakpoint-width in $kt-media-breakpoints {
  @include kt-media-above($breakpoint) {
    @each $inset in 0, 25, 50, 75, 100 {
      .w-#{$breakpoint}-#{$inset} {
        width: percentage(calc($inset / 100)) !important;
      }

      .h-#{$breakpoint}-#{$inset} {
        height: percentage(calc($inset / 100)) !important;
      }

      .w-#{$breakpoint}-auto {
        width: auto !important;
      }

      .h-#{$breakpoint}-auto {
        width: auto !important;
      }
    }
  }
}
