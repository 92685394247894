.card {
  height: 100%;
  max-height: 100%;
  transition: max-height 0.8s ease-in-out;

  &.multi {
    height: auto;

    & + .multi {
      margin-top: 25px;
    }
  }

  &-header {
    padding: 20px;
    font-size: 1.2em;
    font-weight: 500;
    background: transparent;
    height: 70px;
  }

  &__icon {
    margin-right: 15px;
    vertical-align: middle;
    font-size: 1.2em;

    &.la{
      font-size: 1.4em;
    }
  }

  &--shadowed {
    box-shadow: -3px 3px 6px #0000000D;
  }

  .card-body {
    overflow: hidden;

    &.overflow-v{
      overflow: visible;
    }

    @include kt-media-below(xl) {
      padding: 1.5rem;
    }

    .card {
      &--shadowed {
        box-shadow: 0 0 10px #0000001A;
      }
    }
  }

  &--noborder {
    border: none;
  }
}
