.jodit-toolbar__box:not(:empty) {
  border: none !important;
}

.jodit-container:not(.jodit_inline) {
  border: 1px solid #e2e5ec;
  border-radius: 4px;
}

.jodit-toolbar__box {

  .jodit-toolbar-button {

    &__icon {
      height: 100%;
      width: 100%;
      flex: 1;
      /*OVERRIDE JODIT BUTTONS*/
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    .jodit-icon {
      width: auto;
      height: auto;
    }

    svg.jodit-icon {
      max-width: 14px;
      max-height: 14px;
    }
  }
}

.jodit-container {
  &.is-invalid {
    border: 1px solid kt-get($kt-state-colors, danger, base);
  }
}

.jodit-container.jodit_inline .jodit-workplace,
.jodit.jodit_inline .jodit-workplace {
  overflow: visible;
}
