$panel-card: (
  shadow: (
    spread: 10px
  )
);

.card-collapse__wrapper{
  position: absolute;
}

.p-panel{

  &.p-component {
    font-family: inherit;
  }

  .p-toggleable-content {
    flex: 1;

    .p-panel-content {
      height: 100%
    }
  }

  &.card {
    box-shadow: 0 0 kt-get($panel-card, shadow, spread) #0000001A;

    &--collapsed {
      max-height: 70px;
      transition: max-height 0.2s ease-in-out;
    }

    .p-panel-content {
      background: transparent;
    }

    .card-body {
      border-top: 1px solid #EBEDF3;
      height: 100%
    }

    .card-header {
      border-bottom: none;
    }
  }

  &--transparent{

    &.p-panel-toggleable{
      .p-panel-header{
        padding: 0 0 1.5rem 0;
      }
    }

    .p-panel-header{
      background: none;
      border: none;
    }

    .p-panel-content{
      border: none;
      padding: 0;
    }
  }

  .p-panel-header {
    position: relative;

    .p-panel-title {
      font-weight: 500;
      color: kt-get($kt-font-color, text);
      font-size: $font-size-lg;
      line-height: 1.5rem;
      padding-right: 30px;
    }

    .p-panel-icons{
      position: absolute;
      width: 100%;
      height: 100%;

      .p-panel-header-icon {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0;

        &:focus {
          box-shadow: none;
        }

        .pi {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
