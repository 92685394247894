.row-status {
  flex-wrap: unset;
  max-width: 100%;

  .col {
    min-width: 160px;
    max-width: 160px;
    text-align: center;
    border: 2px solid #fff;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .status-container {
      width: 100%;
      height: 100px;
      background-color: #F8F9FB;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}