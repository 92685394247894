body {
  .p-datatable {

    &-auto-layout {
      > .p-datatable-wrapper {
        overflow-x: visible;
      }
    }

    &.p-component {
      font-family: inherit;
    }

    .p-datatable-table {
        border-collapse: separate;
    }

    .p-datatable-thead > tr > th {
      padding: 1rem 1.2rem;
      text-align: left;
      background-color: $kt-content-bg-color;
      border: 1px solid $kt-content-border-color;
    }

    .p-datatable-tbody,
    .p-datatable-thead {
      @include kt-media-below(xl) {
        font-size: 0.85rem;
      }
    }

    .p-datatable-tbody {
      > tr {
        &.p-highlight {
          background: $kt-content-bg-color;
          color: inherit;
        }
      }
    }

    .p-datatable-loading-overlay{
      background: rgba(255,255,255,0.5);

      .p-datatable-loading-icon{
        font-size: 6rem;

        &.pi-spin {
          animation-duration: 1s;
          color: kt-get($kt-state-colors, brand, base);
        }
      }
    }

    .p-sortable-column{
      position: relative;
      &.p-highlight:not(.p-sortable-disabled) {
        background: #F2F3F8;
        color: #333333;

        &:hover{
          background: #E0E0E0;
          color: #333333;

          .p-sortable-column-icon {
            color: #333333;
          }
        }

        .p-sortable-column-icon {
          color: #848484;
        }
      }
      .p-column-title{
        float: left;
      }

      .p-sortable-column-tooltip {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1rem 1.2rem;
      }

      &-icon{
        float: right;
        font-size: 20px;
      }
    }

    .p-filter-column {
      .form-group {
        margin-bottom: 0;
      }

      .p-dropdown{
        width: 100%;
      }
    }

    .p-column-title {
      display: block;
    }

    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      border: 1px solid #E5E5E5;
    }

    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      height: 1px;

      @-moz-document url-prefix() {
        height: inherit;
      }
    }

    .p-paginator {
      display: block;

      & > *{
        float: right;
      }

      .p-dropdown{
        margin-left: 0;
        float: left;
        border: none;

        .p-dropdown-label{
          padding: 0.5rem;
          line-height: 12px;
        }
      }


      .p-dropdown {
        padding: 0;
        align-items: initial;

        .p-dropdown-trigger {
          padding: 0.5rem;
        }
      }

      .p-paginator-pages {
        .p-paginator-page.p-highlight {
          border-radius: 3px;
          background: kt-get($kt-state-colors, brand, base);
        }
      }
    }

    .p-paginator-bottom{
      background: none;
      border: none;
      width: 100%;
      margin-top: 35px;

      .p-dropdown-trigger{
        border: 1px solid #E6E6E6;
        border-left: 0;
      }

      .p-dropdown-label{
        max-width: 40px;
        text-align: center;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid $kt-content-border-color;
      }
    }

    .p-inputtext {
      width: 100%;
    }

    &-noheader {
      thead {
        display: none;
      }
    }

    &--baseline {
      .p-datatable-thead > tr > th,
      .p-datatable-tbody > tr > td {
        vertical-align: top;
      }
    }

    .p-rowgroup-header > td {
      padding: 0;
    }

    &--fixed-header {
      position: fixed;
      word-break: break-word;
    }

    &-row {
      &-flag {
        @each $color, $value in $theme-colors {
          &--#{$color} {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              left: -8px;
              width: 8px;
              height: 100%;
              background: $value;
              border-radius: 8px 0 0 8px;
            }
          }
        }
      }
    }

    &-light {
      .p-datatable-thead > tr > th {
        background: white;
        border: none;
        border-right: 1px solid #E5E5E5;

        &:last-child {
          border-right: none;
        }
      }

      .p-datatable-tbody > tr > td {
        border: none;
        border-top: 1px solid #E5E5E5;
        border-right: 1px solid #E5E5E5;

        &:last-child {
          border-right: none;
        }
      }
    }

    .p-column-filter-menu-button,
    .p-column-filter-clear-button {
      display: none;
    }

    .p-column-header-content {
      justify-content: space-between;
    }

    .p-disabled-row {
      pointer-events: none;
      opacity: 0.5;
    }
    .datatable-multiselect {
        margin: 0 10px 0 0;
        padding: 0;
        span {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
        .p-multiselect-trigger {
            margin-right: 5px;
        }
    }
  }
}
