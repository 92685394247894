.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link i,
.nav-tabs.nav-tabs-line a.nav-link i {
  color: inherit;
}

.nav-tabs {
  &.nav-tabs-line {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
    border-bottom: none;
    padding: 0;

    @include light-scrollbar(6px);

    &:after {
      content: " ";
      display: table;
      border-bottom: 2px solid rgba(21, 183, 255, 0.1);
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    .nav-item {
      white-space: nowrap;
      margin-right: 0;
      padding: 12px;
    }

    &.nav-tabs-line-2x {
      a.nav-link {
        margin-bottom: 0;
      }
    }
  }
}
