$col-after: (
  border: (
    bottom: 30px,
    size: 2px
  )
);

$new-line: (
  width: 100px
);

$icon-size: (
  height: 15px
);

.lead-history {
  $history: &;

  @for $i from 1 through 5 {
    &--#{$i} {
      margin-left: calc(percentage(calc(1 / $i)) / 2);

      #{$history}__col {
        width: percentage(calc(1 / $i));
      }
    }
  }

  &__row {
    $row: &;
    display: flex;
    justify-content: flex-start;
    margin-bottom: kt-get($new-line, width);

    &:last-child {
      margin-bottom: calc(kt-get($new-line, width) / 2);
    }

    &:nth-child(odd) {
      #{$history}__col {
        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }

    &:nth-child(even) {
      #{$history}__col {
        &:first-child {
          &:after {
            content: none;
          }
        }
      }
    }

    &:nth-child(even) {
      #{$history}__col {
        &:first-child {
          &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            border: kt-get($col-after, border, size) solid #EBEDF3;
            transform: translateY(#{kt-get($col-after, border, bottom)});
            border-radius: 0 100% 100% 0;
            border-left: none;
            width: kt-get($new-line, width) * 2;
            height: calc(100% + #{kt-get($new-line, width) + kt-get($col-after, border, size)});
            left: 0;
          }
        }
      }
    }

    &:nth-child(odd):not(:first-child) {
      #{$history}__col {
        &:first-child {
          &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            border: kt-get($col-after, border, size) solid #EBEDF3;
            transform: translateY(#{kt-get($col-after, border, bottom)});
            border-radius: 100% 0 0 100%;
            border-right: none;
            width: kt-get($new-line, width) * 2;
            height: calc(100% + #{kt-get($new-line, width) + kt-get($col-after, border, size)});
            left: - kt-get($new-line, width);
          }
        }
      }
    }

    &:nth-child(odd):last-child {
      #{$history}__col {
        &:last-child {
          &:after{
            content: '';
            border: none;
            background: white;
            height: kt-get($col-after, border, size);
            width: 100%;
          }
        }
      }
    }

    &--right {
      flex-direction: row-reverse;

      &#{$row}--load {
        justify-content: flex-start;
      }
    }
  }

  &__col {
    $col: &;
    height: auto;
    min-height: 130px;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: - kt-get($col-after, border, bottom);
      border-bottom: 2px solid #EBEDF3;
    }
  }

  &__arrow {
    position: absolute;
    bottom: - kt-get($col-after, border, bottom) + calc(kt-get($col-after, border, size) / 2);
    transform: translateY(50%);
    left: 0;
    z-index: 1;
  }

  &__start,
  &__end{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: - kt-get($col-after, border, bottom) + calc(kt-get($col-after, border, size) / 2);
    transform: translateY(50%);
    z-index: 1;

    #{$history}__time {
      position: absolute;
      transform: translateY(100%);
    }
  }

  &__icon {
    background: white;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    z-index: 1;
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }

  @each $name, $color in $leads-source {
    &__source--#{$name}{
      color: $color;
    }
  }
}
