.portlet-tab {
  .nav-tabs{
    padding: 1rem 0px 0px 2rem;

    .nav-link{
      font-size: 16px;
      padding: 0.5rem 1.5rem 1rem;
      font-weight: 600;
      position: relative;
    }

  }
}
