.smart-repair-fullscreen {
    &__img {
        aspect-ratio: 3/2;
        border-radius: 1rem;
        cursor: pointer;
        object-fit: contain;
        width: 180px;
    }

    &__container {
        .p-galleria-item {
            display: block !important;
        }
    }

    &__figcaption {
        color: #ffffff;
        font-size: 17px;
        font-weight: 100;
        margin-top: 1rem;
        text-align: center;
    }

    &__container-thumbnail {
        display: grid;
        gap: 1rem;
        grid-template-columns: 30% 30% 30%;
        grid-template-rows: 1fr;
        margin: 2rem 0px;
    }
}