.datatable-filters {
  padding: 1em 0 2em;

  .form-group{
    margin-bottom: 0;
  }

  .btn-icon {

    i {
      padding-right: 0;
    }
  }
}

.search-input {
  &__button {
    padding-left: 0;
  }
}
