.btn-rpa-maeva {
  background-color: #EFDF11;
  color: $black;
  border-color: #EFDF11;
  border-radius: 3px;

  &:hover, &:focus, &:not(:disabled):not(.disabled):active {
    background-color: #D7C700;
    border-color: #D7C700;
    color: $black;
  }
}

.no-customer-rpa {
  color: $white;
  right: -10px;
  top: -10px;
  background-color: kt-get($kt-base-colors, shape, 4);
  border-radius: 50%;
}

.status-label {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
}

.customer-link-to-rpa-container {
  .infos-amng-container {
    background-color: #E1E3EE;
    border-radius: 10px;

    .infos-amng-title {
      border-bottom: 1px solid $white;
    }
  }

  .founded-customers {
    max-height: 250px;
    overflow: auto;

    @include light-scrollbar();
  }

  .rpa-customer {
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    padding: 10px 35px;
    cursor: pointer;

    &--active {
      border-color: kt-get($kt-state-colors, primary, base);
      background: lighten(kt-get($kt-state-colors, primary, base), 40%);
    }
  }
}
