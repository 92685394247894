.p-datatable-tbody {
  .label-fluidity {
    float: left;
    position: absolute;
    right: 0px;
    bottom: 5px;
    z-index: 1000;
    padding: 5px;
    font-weight: bold;
  }
}

