.immat-plate {
  display: flex;

  .immat {
    padding: 5px 10px;
    border: 1px solid $gray-700;
    align-items: center;
    font-size: 16px
  }

  .plate {
    width: 17px;
    height: 100%;
    background-color: $gray-700;
    color: $white;
    text-align: center;

    &.plate-right {
      color: $gray-700;
    }
  }
}

.p-timeline-event {
  margin-top: 0.5rem;

  .p-timeline-event-content {
    flex: 2 1;
  }
}

.flag-new-data {
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    right: 3px;
    top: 5px;
    transform: translate(50%, -50%);
    background: kt-get($theme-colors, 'danger');
    border: 2px solid kt-get($theme-colors, 'white');
    height: 12px;
    width: 12px;
    border-radius: 100%;
  }
}

.found-customers {
  position: absolute;
  left: 20%;
  width: 200px;

  @include kt-media-below(xl) {
    width: 170px;
    top: 20%;
  }
}

.form-part-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    width: 100%;
    color: $black;
    border-bottom: 1px solid #6F6F6F;
    padding-bottom: 10px;
}

.securycar-handle-modal {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 33px;
    margin-right: -33px;
    @include light-scrollbar();
}

.div-info {
    background-color: #E7F8FF;
    color: $secondary;
    padding: 13px 16px;
    border-radius: 5px;
}
