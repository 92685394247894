.p-paginator {
  background: none;
  border: none;

  .p-dropdown{
    margin-left: 0;
    float: left;
    border: none;

    .p-dropdown-label{
      padding: 0.5rem;
      line-height: 12px;
    }
  }


  .p-dropdown {
    padding: 0;
    align-items: initial;

    .p-dropdown-trigger {
      padding: 0.5rem;
    }
  }

  .p-paginator-pages {
    .p-paginator-page.p-highlight {
      border-radius: 3px;
      background: kt-get($kt-state-colors, brand, base);
    }
  }
}

.p-paginator-bottom{
  background: none;
  border: none;
  width: 100%;
  margin-top: 35px;

  .p-dropdown-trigger{
    border: 1px solid #E6E6E6;
    border-left: 0;
  }

  .p-dropdown-label{
    max-width: 40px;
    text-align: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid $kt-content-border-color;
  }
}
