body {
  .p-datepicker {
    /*OVERRIDE BOOTSTRAP MODALS*/
    z-index: 100000 !important;

    &:not(.p-disabled){
      table {
        td {
          span {
            &:not(.p-highlight):not(.p-disabled) {
              &:hover {
                background: lighten(kt-get($kt-state-colors, primary, base), 30%);
              }
            }
          }
        }
      }
    }

    table {
      th {
        text-align: center;
      }

      td {
        padding: 0.2rem 0.5rem;

        &.p-datepicker-today {
          span {
            border: 1px solid #C4CBE5;
            background: transparent;

            &.p-highlight {
              background: kt-get($kt-state-colors, primary, base);
            }
          }
        }

        span {
          border-radius: 100%;

          &.p-highlight {
            background: kt-get($kt-state-colors, primary, base);
          }
        }
      }
    }

    .p-datepicker-calendar {
      position: relative;

      @include kt-tablet-and-mobile {
        margin-left: -0.60rem;
      }

      &:before {
        content: '';
        position: absolute;
        background: #EDF0FF;
      }
    }

    .p-timepicker {
      display: none;
    }

    .p-datepicker-calendar-container {
      padding: 0 calc(50px - 1.5rem);

      @include kt-tablet-and-mobile {
        padding: 0;
      }
    }
  }

  .p-calendar {
    width: 100%;

    &.p-calendar-w-btn {
      display: flex;
      flex-direction: row-reverse;

      .p-datepicker-trigger {
        &.p-button {
          padding: 0.65rem 1rem;
          height: calc(1.5em + 1.3rem + 2px);
          color: kt-state-color(primary);
          white-space: nowrap;
          background-color: kt-base-color(grey, 1);
          border: 1px solid #e2e5ec;
          border-right: 0px;
          border-radius: 4px 0 0 4px;
          width: auto;
          left: 0;
          font-size: 1rem;
          position: relative;

          &-icon-only{
            .p-button-text{
              padding: 0;
            }

            .p-button-icon-left{
              position: relative;
              top: 0;
              left: 0;
              margin-top: 0;
              margin-left: 0;
              width: 1em;
              height: 1em;
            }
          }
        }
      }

      .p-inputtext{
        border: 1px solid #e2e5ec;
        border-radius: 0 4px 4px 0;
        flex: 1;

        &:enabled{
          &:hover{
            &:not(.p-error){
              border: 1px solid #e2e5ec;
            }
          }

          &:focus {
            &:not(.p-error) {
              color: #495057;
              background-color: #fff;
              border: 1px solid #9aabff;
              outline: 0;
              box-shadow: none;
            }
          }
        }
      }

      &.is-invalid {
        .p-inputtext {
          border: 1px solid kt-get($kt-state-colors, danger, base);
        }
        .p-datepicker-trigger{
          &.p-button{
            border: 1px solid kt-get($kt-state-colors, danger, base);
          }
        }
      }
    }

    .p-inputtext {
      display: block;
      width: 100%;
      height: calc(1.5em + 1.3rem + 2px);
      padding: 0.65rem 1rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e2e5ec;
      border-radius: 4px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:enabled {
        &:hover {
          border: 1px solid #e2e5ec;
        }

        &:focus {
          color: #495057;
          background-color: #fff;
          border-color: #9aabff;
          outline: 0;
          box-shadow: none;
        }
      }
    }

    .p-datepicker{
      min-width: 200px;
    }
  }

  .p-datepicker-overlay {
    min-width: 450px;

    @include kt-tablet-and-mobile {
      min-width: 0;
      max-width: 100%;
    }

    @include kt-mobile {
      position: fixed;
      width: 100%;
      top: inherit !important;
      bottom: 0;
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .p-calendar {
      .p-datepicker-inline {
        box-shadow: none;
        width: 100% !important;
      }
    }

    .p-overlaypanel-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .p-datepicker-group-container {
      flex: 1 0 auto;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .p-datepicker-group {
      width: 100%;
      height: 100%;
      flex: 1 0 auto;

      &--hour,
      &--date {
        height: 325px;

        @include kt-tablet-and-mobile {
          height: auto;
        }
      }

      &--hour {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .p-datepicker-footer {
      padding: 15px 50px;

      @include kt-tablet-and-mobile {
        padding: 15px 0;
      }

      .btn {
        width: 100px;
      }
    }

    .p-datepicker-time {
      &__input {
        height: 80px;
        width: 80px;
        color: kt-get($kt-state-colors, primary, base);
        border: 1px solid #C4CBE5;
        border-radius: 2px;
        font-size: 35px;
        text-align: center;
      }
    }

    .p-datepicker-tabs {
      width: 100%;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #E1E3ED;
      padding-bottom: 0.857rem;
      color: white;
    }

    .p-datepicker-tab {
      margin: 0 2rem;
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include kt-tablet-and-mobile {
        margin: 0 1rem;
      }

      &__content {
        background: kt-get($kt-state-colors, primary, base);
        padding: 6px 15px;
        border-radius: 23px;
        cursor: pointer;
      }
    }

    .p-dropdown {
      .p-dropdown-label {
        &.p-inputtext {
          border: none;
          height: auto;
        }
      }
    }

    .p-monthpicker {

      .p-monthpicker-month {
        height: 50px;

        &.p-highlight {
          background: kt-get($kt-state-colors, primary, base);
        }
      }
    }
  }
}
