@mixin inactive-button($self, $mobile) {
  background: #E6E6E6;
  color: white;

  @if $mobile {
    @include kt-tablet-and-mobile {
      background: none;
    }
  }

  #{$self}__symbol {
    #{$self}__label {
      //Override symbols
      background: #ECECEC !important;

      @if $mobile {
        @include kt-tablet-and-mobile {
          background: $white !important;
          opacity: 0.4;
        }
      }
    }
  }
  #{$self}__icon {
    color: white;
  }
};

.action-button {
  $self: &;
  background: #F8F9FB;
  box-shadow: 0px 3px 6px #00000029;
  padding: 13px 5px 10px 5px;
  min-width: 110px;
  width: 120px;
  max-width: 120px;
  min-height: 86px;
  height: 107px;
  max-height: 107px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
  transition: all 0.15s ease;

  &--background {
    background-color: $white;
    padding: 0.3rem;
    border-radius: 0.3rem;
  }

  &--list {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    max-width: initial;
    min-height: initial;
    height: initial;
    justify-content: flex-start;
    border-radius: 14px;
    padding: 8px;

    @include kt-tablet-and-mobile {
      font-size: $font-size-base;
      padding: 10px 5px;
      min-width: auto;
      width: auto;
      max-width: 100%;
      margin: 0;
      justify-content: inherit;
      background: none;
      box-shadow: none;
    }

    #{$self}__label {
      @include kt-tablet-and-mobile {
        background: $white !important;
        width: 50px !important;
        height: 50px !important;
      }
    }

    #{$self}__icon.la {
      @include kt-tablet-and-mobile {
        font-size: 2.2rem;
        color: kt-get($kt-state-colors, primary, base) !important;
      }
    }

    #{$self}__title {
      font-size: 13px;
      font-weight: $font-weight-bold;
      line-height: 1.3rem;
      text-align: left;
      padding-top: 0.2rem;
      padding-left: 0.5rem;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      @include kt-tablet-and-mobile {
        display: none;
      }
    }

    &#{$self}--inactive {
      @include inactive-button($self, true);
    }

    &#{$self}--disabled {
      @include inactive-button($self, true);
    }

    .symbol .symbol-label {
        border-radius: 10px;
    }
  }

  &__icon.la {
    font-size: 1.7rem;
  }

  &:hover {
    transform: scale(1.02);
  }

  &__title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    line-height: 1.3rem;
  }

  @each $color, $value in $theme-colors {
    &--#{$color} {
      color: $value;
    }
  }

  @each $color, $value in $theme-colors {
    &--background-#{$color} {
      background: $value;

      &#{$self}--active {
        box-shadow: inset 0px 0px 6px 2px darken($value, 10%);
      }
    }
  }

  &--inactive {
    @include inactive-button($self, false);
  }

  &--disabled {
    cursor: default;

    &:hover {
      transform: none;
    }

    @include inactive-button($self, false);
  }
}
