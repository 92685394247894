.p-cascadeselect {
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.30rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;

  &.is-invalid{
    border: 1px solid kt-get($kt-state-colors, danger, base);
  }

  &.p-disabled {
    background-color: kt-get($kt-base-colors, grey, 1);
    opacity: 1;
  }

  .p-cascadeselect-label {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
  }
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  background: kt-get($kt-state-colors, primary, base);
}
