.smart-repair-timeline {
    &__text-tranform {
        white-space: nowrap;
    }

    &__rounded {
        background-color: #fff;
        border-radius: 25px;
        height: 15px;
        width: 15px;
    }
    
    &__textLabel {
        &--color{
            color: #C8C8D5;
        }
        &--bg {
            background-color: #F2F3F8;
        }
    }
    // Theme colors
    @each $color,
    $name in $theme-colors {
        &__label-#{$color} {
            border: 4px solid $name;
        }

        &__status-text-#{$color} {
            color: $name;
        }
    }

}

.p-timeline-event:not(:first-child) {
    .smart-repair-timeline__text-tranform {
        display: inline-block;
        transform: translate(-50%);
    }

    .p-timeline-event-separator {
        transform: translate(-5px);
    }
}