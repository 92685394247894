.p-chips {
  width: 100%;

  .p-inputtext{
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    border: 1px solid $gray-300;
  }
  .p-chips-multiple-container {

    &:not(.p-disabled) {
      &.p-focus {
        box-shadow: none;
        border-color: kt-get($kt-state-colors, primary, base);
      }
      &:hover {
        border-color: kt-get($kt-state-colors, primary, base);
      }
    }

    .p-chips-token{
      background: kt-get($kt-base-colors, label, 2);
      border-radius: 50px;
      margin: 3px;
    }
  }

  &-token-label {
    color: $white;
  }

  &-chips-token-icon {
    color: $white;
  }
}
