body{
  .p-datepicker-year, .p-datepicker-month {
    background: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    padding: 1rem;
    color: #848484;
  }

  select.p-datepicker-year, .p-datepicker-month {
    color: #666666;
  }
  .p-calendar{
    text-align: center;

    &--center{
      width: 100%;

      .p-datepicker{
        margin: 0 auto;
      }
    }

    .p-datepicker{
      min-width: 300px;

      &-inline{
        box-shadow: 0px 3px 6px #00000029;
        border: none;
        border-radius: 4px;
        width: 100%;

        .p-datepicker-group-container{
          width: 100%;
        }
      }
    }

    &.p-calendar-w-btn{
      flex-direction: row;

      .p-inputtext {
        border-radius: 4px 0 0 4px;
        border-right: 0;

        &:enabled{
          &:hover{
            &:not(.p-error){
              border-right: none;
            }
          }

          &:focus{
            &:not(.p-error){
              border-right: none;
            }

            ~.p-button{
              border: 1px solid #9aabff;
              border-left: 0;
            }
          }
        }
      }

      .p-datepicker-trigger{
        &.p-button{
          border-radius: 0 4px 4px 0;
          border-right: 1px solid #e2e5ec;
          border-left: none;
          background-color: white;
          padding: 0.5rem;

          .pi-calendar{
            font-size: 1.6em;
            color: #C8C8D5;
          }
        }
      }
    }
  }
}
