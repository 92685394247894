.lead-rows {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include light-scrollbar();
}

$lead-config: (
  margin-bottom: 1.5rem
);

.lead-row {
  $self: &;
  border-radius: 8px;
  border: 1px solid $gray-200;
  border-left: 0;
  box-shadow: 0px 1px 3px #00000029;
  margin-bottom: kt-get($lead-config, margin-bottom);
  position: relative;
  padding: 0 20px;
  clear: both;
  flex: 1 0 auto;
  width: 100%;

  @include kt-tablet-and-mobile {
    padding: 0 15px 0 25px;
  }

  &__wrapper {
    display: flex;
    overflow: hidden;

    @include kt-tablet-and-mobile {
      flex-direction: column;
    }
  }

  &__type {
    position: absolute;
    left: 0;
    height: 100%;
    width: 10px;
    border-radius: 8px 0 0 8px;

    @each $name, $color in $leads-type {
      &--#{$name}{
        background: $color;
      }
    }
  }

  &__picture-wrapper {
    flex: 0 1 120px;
    max-width: 120px;
    margin: 0 5px 0 15px;
    padding: 5px 0;
    height: auto;
    display: flex;
    justify-content: center;

    @include kt-media-range(xlg, xxl) {
      display: none;
    }

    @include kt-tablet-and-mobile {
      display: none;
    }

    &--marketing-campaign {
      max-width: 180px;
    }
  }

  &__picture {
    width: 100%;
    max-height: 150px;
    object-fit: none;
    object-position: top;

    &--online {
      height: auto;
      align-self: center;
      object-fit: contain;
    }

    &--marketing-campaign {
      align-self: center;
      width: 200px;
    }
  }

  &__content {
    flex: 1 1 auto;
    padding-left: 20px;
    overflow: hidden;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include kt-tablet-and-mobile {
      padding-left: 0;
    }
  }

  &__actions {
    flex: 0 1 auto;
    min-width: 200px;
    display: flex;
    margin-left: 15px;
    flex-direction: column;
    justify-content: center;

    @include kt-tablet-and-mobile {
      margin-left: 0;
    }
  }

  &__wrapper-label {
      width: 30%;
      text-wrap: balance;
  }

  &__junction {
    background: white;
    width: 50px;
    height: 23px;
    position: absolute;
    right: 10%;
    top: 0;
    transform: translateY(-100%);

    &:before,
    &:after {
      content: '';
      width: 70px;
      height: 24px;
      background: transparent;
      position: absolute;
      top: 0;
      border-radius: 100%;
    }

    &:before {
      left: -50px;
      box-shadow: inset -3px 0px 4px -2px #00000029;
      border-right: 20px solid white;
    }

    &:after {
      right: -50px;
      box-shadow: inset 3px 0px 4px -2px #00000029;
      border-left: 20px solid white;
    }
  }

  &--children {
    width: 70%;
    margin-right: 5%;
  }

  &__unlink {
    display: flex;
    align-items: center;
    margin-left: 30px;
    white-space: nowrap;
    cursor: pointer;
  }

  &--noresult {
    height: 100%;
  }

  &--selectable {
    cursor: pointer;
  }

  &--selected {
    border: 1px solid kt-get($kt-state-colors, primary, base);
    border-left: none;

    &:after {
      font-family: 'Line Awesome Free';
      font-weight: 900;
      content: "\f00c";
      background: kt-get($kt-state-colors, primary, base);
      color: white;
      text-align: center;
      border-radius: 100%;
      font-size: $h2-font-size;
      height: 35px;
      width: 35px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
    }
  }

  &__separator{
    background: $light-gray;
    color: white;
    text-align: center;
    border-radius: 100%;
    font-size: $h2-font-size;
    height: 35px;
    width: 35px;
    position: absolute;
    bottom: 0;
    right: 15%;
    transform: translate(0, calc(100% - calc(#{kt-get($lead-config, margin-bottom)} / 2 - 2px)));
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .la {
      font-size: 1.5rem;
    }
  }
}
