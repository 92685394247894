.smart-repair-modal {
    &__paragraph {
        font-size: 14px;
    }

    &__img {
        width: 170px;
        object-fit: contain;
        aspect-ratio: 3/2;
        border-radius: 1rem;
    }
}