.p-overlaypanel {
  z-index: 10000 !important;
}


.p-component.legend-icon-panel {
  font-family: inherit;

  .p-overlaypanel-content {

    .row {
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
