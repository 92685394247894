.p-inline-message {
  padding: 15px 30px;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  @each $name, $color in $theme-colors {
    &-#{$name} {
      border-left: 10px solid $color !important;
      background-color: lighten($color, 28%) !important;
      color: $color !important;
    }
  }
}
