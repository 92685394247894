.react-dropdown-tree-select {
  width: 100%;

  &--dashboard {
    .dropdown {
      width: 100% !important;
      padding: 0.15rem 0;
      .dropdown-trigger {
        border-radius: 1.25rem;
        width: auto;
        background-color: $primary-light;
        overflow: hidden !important;
        .search {
          background-color: $primary-light;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }

  &--placeholder {
    .tag-list {
      display: block;

      .tag-item:last-child {
        display: block;
        padding: 0 5px;

        ::placeholder {
          color: $gray-500;
        }

        input {
          width: 100%;
        }
      }

      .tag-item ~ .tag-item:last-child {
        display: inline-block;
      }
    }
  }

  .dropdown {
    width: 100%;

    .dropdown-trigger {
      @extend .form-control;
      height: calc(1.5em + 1.3rem + 1px);
      padding: 0;

      &.arrow {
        &:after {
          content: none !important;
        }
      }
    }
  }

  .tag-item {
    margin: 5px;

    .search {
      padding: 2px 0;
      border: 1px solid transparent;
    }
    .placeholder {
      font-weight: 600;
      border: 1px solid transparent;
      position: relative;
      top: 3px;
    }
  }

  .tag {
    border-radius: 15px;
    background: kt-get($kt-state-colors, secondary, base);
    color: $white;
    padding: 2px 10px;
  }

  .tag-remove {
    font-size: 14px;
    color: white;
    padding: 0 0 0 10px;
  }

  .dropdown-content {
    min-width: 100%;
    z-index: 3 !important;
    width: 390px;

    .search {
      padding: 0.5rem !important;
    }

    .node {
      display: flex;
      align-items: center;

      > label {
        margin-bottom: 0;
      }
    }

    .toggle {
      font-size: 18px;
      margin-right: 10px;
      color: black;
    }

    .checkbox-item,
    .radio-item {
      margin: 0 5px 0 0;
    }
  }

  .infinite-scroll-component {
    max-height: 400px;

    @include light-scrollbar();
  }
}
