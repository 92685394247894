.p-toast {
  width: auto;
  opacity: 1;

  &.p-component {
    font-family: inherit;
  }

  &-summary {
    color: $white;
  }

  .p-toast-message {
    box-shadow: 0;
    border-radius: 4px;
  }

  .p-toast-message .p-toast-message-content {
    align-items: center;
    padding: 16px 26px;
  }

  .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin-left: 0;
  }

  .p-toast-message .p-toast-message-content .p-toast-summary {
    font-size: 1.1rem;
    font-weight: 300;
  }

  &-message-icon {
    display: none;
  }

  .p-toast-message {
    &.p-toast-message-success {
      background: kt-get($theme-colors, success);
    }
    &.p-toast-message-info {
      background: kt-get($theme-colors, primary);
    }
    &.p-toast-message-warn {
      background: kt-get($theme-colors, warning);
    }
    &.p-toast-message-error {
      background: kt-get($theme-colors, danger);
    }
  }



  .p-toast-message .p-toast-icon-close {
    width: inherit;
    height: inherit;
    color: $white !important;
    opacity: 0.75;
    box-shadow: none !important;

    &:hover {
      background: none;
  
      .p-toast-icon-close-icon {
        color: $black;
      }
    }

    .p-toast-icon-close-icon {
      margin-left: 10px;
    }
  }
}