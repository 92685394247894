.p-divider {
  @each $color, $value in $theme-colors {
    &-#{$color} {
      &.p-divider-horizontal {
        &:before {
          border-top-color: $value;
        }
      }
    }
  }
}

.p-divider-dotted {
  &.p-divider-horizontal {
    &:before {
      border-left-style: none;
    }
  }
}
