$switch-hover-color: #B7B7B7;

.switch-input {
  $self: &;
  margin-top: 5px;
  display: flex;
  align-items: center;

  .p-inputswitch{
    min-width: 40px;

    @include kt-media-below(xl) {
      min-width: 30px;
    }

    &:not(.p-disabled) {
      &:hover {
        .p-inputswitch-slider{
          background: $switch-hover-color;
        }
      }
    }

    .p-inputswitch-slider {
      background-color: kt-get($kt-state-colors, danger, base);
    }

    &.p-inputswitch-checked {

      .p-inputswitch-slider {
        background-color: kt-get($kt-state-colors, success, base);

        &:before {
          background: #ffffff;
        }
      }
    }
  }

  &__label{
    margin: 0 10px;

    @include kt-media-below(xl) {
      margin: 0 5px;
    }

    &:first-child{
      margin-left: 0;
      color: kt-get($kt-state-colors, secondary, base);
    }

    &:last-child{
      margin-right: 0;
      color: kt-get($kt-state-colors, secondary, base);
    }
  }

  &--inactive {

    #{$self}__label {

      &:first-child {
        color: kt-get($kt-state-colors, danger, base);
      }

      &:last-child {
        color: kt-get($kt-state-colors, secondary, base);
      }
    }
  }

  &--active {

    #{$self}__label {

      &:first-child {
        color: kt-get($kt-state-colors, secondary, base);
      }

      &:last-child {
        color: kt-get($kt-state-colors, success, base);
      }
    }
  }

  &--neutral {

    #{$self}__label {

      &:first-child {
        color: kt-get($kt-state-colors, secondary, base);
      }

      &:last-child {
        color: kt-get($kt-state-colors, secondary, base);
      }
    }

    .p-inputswitch {

      .p-inputswitch-slider {
        background: $switch-hover-color;

        &:before {
          transform: translateX(0.725rem);
        }
      }
    }
  }

  @each $color, $value in $theme-colors {
    //Color of all toggle and label
    &--#{$color} {
      .p-inputswitch {

        .p-inputswitch-slider {
          background-color: $value;

          &:hover {
            background: $value;
          }
        }

        &.p-inputswitch-checked {

          .p-inputswitch-slider {
            background-color: $value;

            &:hover {
              background: $value;
            }
          }
        }
      }

      .p-inputswitch-checked {

        &:first-child {
          color: $value;
        }

        &:last-child {
          color: $value;
        }
      }
    }

    //Active label left color
    &--active {
      &#{$self}--active-label-left-#{$color} {
        #{$self}__label {
          &:first-child {
            color: $value;
          }
        }
      }
    }

    //Inactive label left color
    &:not(&--active) {
      &#{$self}--inactive-label-left-#{$color} {
        #{$self}__label {
          &:first-child {
            color: $value;
          }
        }
      }
    }

    //Active label right color
    &--active {
      &#{$self}--active-label-right-#{$color} {
        #{$self}__label {
          &:last-child {
            color: $value;
          }
        }
      }
    }

    //Inactive label right color
    &:not(&--active) {
      &#{$self}--inactive-label-right-#{$color} {
        #{$self}__label {
          &:last-child {
            color: $value;
          }
        }
      }
    }

    //Active toggle color
    &--active {
      &#{$self}--active-toggle-#{$color} {
        .p-inputswitch {
          &-checked{
            .p-inputswitch-slider {
              background-color: $value;
            }
          }
        }
      }
    }

    //Inactive toggle color
    &:not(&--active) {
      &#{$self}--inactive-toggle-#{$color} {
        .p-inputswitch {
          .p-inputswitch-slider {
            background-color: $value;
          }
        }
      }
    }
  }
}
