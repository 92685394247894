//
// Modal
//




// Base
.modal {

    @include kt-media-below(lg) {
        z-index: 1105;
    }

    &-backdrop {
        @include kt-media-below(lg) {
            z-index: 1104;
        }
    }

    &-open {
        .modal {
            @include kt-tablet-and-mobile {
                padding: 0 !important;
            }
        }
    }

    &--fullwidth {
        width: 100%;

        .modal-dialog {
            width: 90% !important;
            max-width: none;
        }
    }

    &-hidden {
        opacity: 0;
    }

    .modal-dialog {
        min-height: calc(100vh - 3.5rem);
        display: flex;
        align-items: center;
        margin: 1.75rem auto;

        @include kt-media-below(md) {
            margin: 0 auto;
        }
    }

    .modal-content {
        @include kt-rounded {
            border-radius: $kt-border-radius;
        }

        .modal-header {
            position: absolute;
            z-index: 1;
            right: 0;
            padding: 1.5rem 2rem 0em;

            .modal-title {
                font-weight: 500;
                font-size: 1.3rem;
                color: kt-base-color(label, 4);

                small {
                    font-weight: 400;
                    font-size: 0.9rem;
                    color: kt-base-color(label, 2);
                }
            }

            .close {
                outline:none !important;
                color: kt-base-color(label, 2);

                @include kt-close-icon();
                @include kt-hover-transition();

                &:before {
                    font-size: 2.5rem;
                }

                span {
                    display: none;
                }

                &:hover {
                    color: kt-brand-color();
                }
            }
        }
        .modal-footer {
            display: inline;
        }

        .modal-bar {
            display: block;
            height: 2px;
            width: 47%;
            &--color-bar-primary {
                border-top: 4px solid kt-state-color(primary, base);
            }
            
            &--color-bar-success {
                border-top: 4px solid kt-state-color(success, base);
            }
        }

        .modal-company,
        .modal-pro {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .modal--success {
            color: kt-state-color(success, base);
        }
        
        
        .modal--primary {
            color: kt-state-color(primary, base);
        }

        .modal-customize-nav {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            width: 75%;
            @include kt-media-above(xl) {
                width: 35%;
            }
        }
    }

    // Stick to bottom right
    &.modal-sticky-bottom-right {
        $width-default: 500px;
        $width-lg: 600px;

        padding: 0 !important;
        max-width: $width-default;

        &.modal-sticky-lg {
            max-width: $width-lg;
        }

        height: auto;
        position: fixed;
        left: auto;
        top: auto;
        bottom: 25px;
        right: 25px;
        margin: 0;
        box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);

        @include kt-rounded {
            border-radius: $kt-border-radius;
        }

        @include kt-tablet-and-mobile {
            bottom: 10px;
            right: 10px;
        }

        @include kt-mobile {
            max-width: 90%;
        }

        .modal-dialog {
            position: static;
            max-width: $width-default;
            width: auto;
            margin: 0;

            @include kt-rounded {
                border-radius: $kt-border-radius;
            }

            .modal-content {
                border: 0;
                @include kt-rounded {
                    border-radius: $kt-border-radius;
                }
            }
        }

        &.modal-sticky-lg {
            .modal-dialog {
                max-width: $width-lg;
            }
        }
    }
}

.modal-body {
    &.modal-body-fit {
        padding: 0;
    }
    &.modal-body-x-fit {
        padding-left: 0;
        padding-right: 0;
    }
    &.modal-body-y-fit {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.modal-title {
    margin-top: 1.5rem;
}

@include kt-media-above(xl) {
    .modal-dialog.modal-xl {
        max-width: kt-media-breakpoint(xl);
    }
}
