.loader{
  height: 100%;
  width: 100%;
  z-index: 1000;

  &--block {
    position: relative;
    min-height: 10rem;
  }

  &--overlay{
    position: absolute;
    top: 0;
    left: 0;
  }

  &--content {
    height: 100vh;
  }

  &__content{
    height: auto;
    width: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    opacity: 0.5;
  }
}
