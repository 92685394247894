.customer-handband {
  overflow: hidden;
  position: relative;

  .customer-handband__pic {
    height: 150px;
    object-fit: none;
    object-position: top;
    width: 240px;

    &--small {
      height: 50px;
      object-fit: cover;
      object-position: center -5px;
      width: 55px;

      @include kt-tablet-and-mobile {
        display: none;
      }
    }
  }

  .headband__content {
    margin-left: 0;
    width: 100%;

    &--scroll {
        padding-left: 0;
    }
  }
}

.fullheadband {
  display: flex;

  @include kt-media-below(xl) {
    align-items: center;
    flex-direction: column;
  }

  &__pic {
    align-items: flex-end;
    display: flex;
    flex: 0 1 auto;
    min-width: 150px;

    img {
      width: 100%;
    }

    @include kt-media-below(xxl) {
      display: none;
    }
  }

  &__divider{
    @include kt-media-below(xl) {
      display: none;
    }
  }

  &__col {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;

    @include kt-media-below(xxl) {
      justify-content: center;
    }

    @include kt-media-below(xl) {
      padding: 15px 0 0;
    }

    @include kt-tablet-and-mobile {
      flex: 0 1 auto;
      margin: 0 auto;
      text-align: center;
    }
  }

  &__part{
    display: flex;

    @include kt-media-below(lg) {
        flex: 1;
        justify-content: center;
        width: 100%;
    }

    @include kt-mobile() {
        justify-content: left;
        overflow-x: auto;
    }

    @include kt-media-above(xxl) {
      &:first-child{
        flex: 1 1 auto;
      }
    }
  }
  @include kt-media-above(lg) {
      &__actions {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 14px 10px;
        justify-content: flex-end;
        padding: 20px 1.25rem;

        > div {
            flex: 0 0 170px;
        }
      }
  }
}

.scrollheadband {
    @include kt-media-above(lg) {
        &__actions {
            align-items: flex-start;
            display: flex;
            gap: 14px 10px;
            justify-content: flex-end;
            padding: 20px 1.25rem;

            > div {
                flex: 0 0 170px;
            }
        }
    }
}

.linkheadband {
  &__pic {
    flex: 0 1 auto;

    @include kt-media-below(xlg) {
      display: none;
    }
  }

  &__row {
    align-items: center;
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;

    @include kt-media-below(xlg) {
      justify-content: center;
    }
  }

  &__text {
    margin: 1rem 2rem 0.5rem 0;
  }

  &__actions {
    margin: 0.5rem 0 1rem;
  }
}
