.files-upload {
  &__previews {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  &__wrapper {
    overflow: hidden;
    padding-right: calc(35px / 2);
  }

  &__preview {
    border: 1px solid $light-gray;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    max-width: 140px;
    text-align: center;
    cursor: pointer;
    width: 100%;
  }

  &__remove {
    $self: &;
    position: absolute;
    right: 0;
    top: 5px;
    border: 1px solid $light-gray;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transform: translateX(50%);
    background: white;

    &:hover{
      border: 1px solid kt-get($kt-state-colors, danger, base);
      background: kt-get($kt-state-colors, danger, base);

      #{$self}-icon {
        color: white;
      }
    }

    &-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      //Override Line Awesome
      line-height: 2rem !important;
      font-size: 1.7rem !important;
      color: $light-gray;
    }
  }

  &__input{
    display: none;
  }

  &__name{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
